import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Users } from 'lucide-react';

export default function OverOns() {
  return (
    <>
      <Helmet>
        <title>Over Ons | Benzinekosten berekenen</title>
        <meta name="description" content="Leer meer over het team achter Benzinekosten berekenen en onze missie om brandstofkosten inzichtelijk te maken." />
        <link rel="canonical" href="https://www.benzinekosten-berekenen.nl/over-ons" />
        <meta property="og:title" content="Over Ons | Benzinekosten berekenen" />
        <meta property="og:description" content="Leer meer over het team achter Benzinekosten berekenen en onze missie." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.benzinekosten-berekenen.nl/over-ons" />
      </Helmet>

      <header className="text-center mb-12">
        <div className="flex items-center justify-center gap-2 mb-4">
          <Users className="w-8 h-8 text-blue-600" />
          <h1 className="text-3xl font-bold text-gray-800">
            <a href="https://www.benzinekosten-berekenen.nl/" className="hover:text-blue-600 transition-colors">
              Benzinekosten berekenen
            </a>
          </h1>
        </div>
      </header>

      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-4">Over onze website en wat je er mee kan</h2>
            <p className="text-gray-700 leading-relaxed">
              Je vraagt je misschien af waarom we deze website zijn gestart en wie wij zijn. Daar hebben we deze pagina voor gemaakt. Wij zijn een klein team wat de kosten van een rit inzichtelijk wil maken. Voor je vertrekt kun je op onze website berekenen wat de reis je gaat kosten. Door enkele variabelen in te vullen rekent de website uit wat de totale kosten zijn voor de rit. Handig als je met een groep vrienden gaat is dat je ook het aantal passagiers kunt ingeven. Zo kun je dus berekenen wat de kosten zijn per persoon.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              De berekening gaat niet alleen uit van de kosten van de benzine, maar ook wordt er rekening gehouden met het verbruik, kosten voor het onderhoud en met de heen en terugreis indien gewenst. Wij zijn dit initiatief gestart om een makkelijke kosten inschatting te maken van je reis. Het is nogal omslachtig om te berekenen en wilden dit beschikbaar maken voor iedereen. Zo is dit idee ontstaan voor onze website. Eenvoudig en snel je kosten uitrekenen voor een rit. Dit kan binnen Nederland zijn maar ook voor ritten buiten Nederland kun je onze tool gebruiken. Net als het internet willen wij niet gehinderd worden door grenzen, dat is gelukt met onze service.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Voor wie is onze benzinekosten bereken tool handig?</h2>
            <p className="text-gray-700 leading-relaxed">
              Onze dienst is voor iedereen handig die wil weten wat een bepaalde rit gaat kosten. Vaak wordt er onderschat wat een ritje met de auto kost. Er wordt alleen gekeken naar de stand van de benzine. Heb ik nog voldoende benzine? Of moet ik onderweg nog ergens tanken. Om nu duidelijk te maken wat een weekendje weg kost inclusief het vervoer kun je bijvoorbeeld onze website gebruiken.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Wij vonden dat er op internet nog een dergelijke dienst ontbrak. Een ritje met de auto gaat namelijk niet alleen om het verbruik van brandstof maar zeker ook de kosten voor het onderhoud aan de auto. Ook die moet je meetellen in de totaal berekening. Onze dienst is ook erg handig voor ondernemers. Wanneer je als ondernemer een offerte moet maken kun je met onze website de benzinekosten berekenen. Zo weet je precies wat de rit naar je klant elke dag gaat kosten. Deze kosten kun je dan weer op een eenvoudige manier verdisconteren in je offerte. Het was nog niet eerder zo makkelijk om de kosten van een autorit inzichtelijk te maken.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Op vakantie naar het buitenland?</h2>
            <p className="text-gray-700 leading-relaxed">
              Ga je naar het buitenland op vakantie met de auto? Reken dan eens uit wat deze rit je gaat kosten. Vaak kijken we alleen naar de kosten van de locatie en "vergeten" we de kosten van de reis. Met onze dienst reken je dit met 1 druk op de knop uit. Weet je nog niet precies het adres waar je heen gaat? Geen probleem geef dan gewoon de plaats in en onze tool doet de rest voor je. Wij hopen zo voor iedereen een mooie en vooral handige aanvulling te zijn voor het berekenen van de kosten van een autorit.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Heb je nog suggesties voor ons? Dan horen wij dit graag via onze contact pagina.
            </p>
          </section>
        </div>
      </div>
    </>
  );
}