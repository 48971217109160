import React, { useState, useCallback, useEffect } from 'react';
import { Calculator, Info, Car, Euro, Fuel, Route, ArrowRight, RotateCw } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';

// Workaround for routing machine types
declare module 'leaflet' {
  namespace Routing {
    function control(options: any): any;
  }
}

function RoutingMachine({ from, to, onRouteFound }: { 
  from: [number, number]; 
  to: [number, number];
  onRouteFound: (distance: number) => void;
}) {
  const map = useMap();

  useEffect(() => {
    if (!map || !from || !to) return;

    let routingControl: any = null;
    let isMounted = true;

    try {
      // Create routing control with empty waypoints first
      routingControl = L.Routing.control({
        waypoints: [],
        routeWhileDragging: false,
        showAlternatives: false,
        fitSelectedRoutes: true,
        lineOptions: {
          styles: [{ color: '#3B82F6', weight: 4 }]
        }
      }).addTo(map);

      // Set waypoints after control is added to map
      routingControl.setWaypoints([
        L.latLng(from[0], from[1]),
        L.latLng(to[0], to[1])
      ]);

      routingControl.on('routesfound', (e: any) => {
        if (isMounted && e.routes && e.routes.length > 0) {
          const distance = e.routes[0].summary.totalDistance / 1000; // Convert to km
          onRouteFound(distance);
        }
      });
    } catch (error) {
      console.error('Error setting up routing control:', error);
    }

    return () => {
      isMounted = false;
      if (routingControl) {
        try {
          // Clear waypoints first
          routingControl.setWaypoints([]);
          
          // Remove all event listeners
          routingControl.off('routesfound');
          
          // Remove the control from the map
          map.removeControl(routingControl);
          
          // Clear any remaining references
          routingControl = null;
        } catch (error) {
          console.error('Error cleaning up routing control:', error);
        }
      }
    };
  }, [map, from, to, onRouteFound]);

  return null;
}

function App() {
  const [fromAddress, setFromAddress] = useState<string>('Amsterdam');
  const [toAddress, setToAddress] = useState<string>('Maastricht');
  const [fromCoords, setFromCoords] = useState<[number, number] | null>(null);
  const [toCoords, setToCoords] = useState<[number, number] | null>(null);
  const [distance, setDistance] = useState<string>('');
  const [consumption, setConsumption] = useState<string>('8');
  const [fuelPrice, setFuelPrice] = useState<string>('2,089');
  const [result, setResult] = useState<number | null>(null);
  const [includeReturn, setIncludeReturn] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const searchAddress = async (address: string): Promise<[number, number] | null> => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}&countrycodes=nl`
      );
      const data = await response.json();
      if (data && data[0]) {
        return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
      }
      return null;
    } catch (error) {
      console.error('Error searching address:', error);
      return null;
    }
  };

  const handleSearch = async () => {
    setIsSearching(true);
    const fromResult = await searchAddress(fromAddress);
    const toResult = await searchAddress(toAddress);
    
    if (fromResult && toResult) {
      setFromCoords(fromResult);
      setToCoords(toResult);
    } else {
      alert('Kon één of beide adressen niet vinden. Controleer de ingevoerde adressen.');
    }
    setIsSearching(false);
  };

  const handleRouteFound = (routeDistance: number) => {
    const totalDistance = includeReturn ? routeDistance * 2 : routeDistance;
    setDistance(totalDistance.toFixed(1).replace('.', ','));
  };

  const calculateCost = useCallback(() => {
    if (!distance || !consumption || !fuelPrice) return;
    
    const distanceNum = parseFloat(distance.replace(',', '.'));
    const consumptionNum = parseFloat(consumption.replace(',', '.'));
    const priceNum = parseFloat(fuelPrice.replace(',', '.'));
    
    if (isNaN(distanceNum) || isNaN(consumptionNum) || isNaN(priceNum)) return;
    
    const litersUsed = (distanceNum * consumptionNum) / 100;
    const totalCost = litersUsed * priceNum;
    setResult(totalCost);
  }, [distance, consumption, fuelPrice]);

  useEffect(() => {
    if (distance) {
      calculateCost();
    }
  }, [distance, consumption, fuelPrice, calculateCost]);

  return (
    <>
      <Helmet>
        <title>Benzinekosten berekenen | Gratis Brandstofkosten Calculator 2024</title>
        <meta name="description" content="Bereken gratis uw brandstofkosten voor elke autorit. Actuele brandstofprijzen, routeplanner en nauwkeurige berekening van benzine- en dieselkosten. Plan uw route en bespaar!" />
        <meta name="keywords" content="benzinekosten berekenen, brandstofkosten calculator, benzineprijs, routeplanner, brandstofverbruik, autokosten nederland" />
        <link rel="canonical" href="https://www.benzinekosten-berekenen.nl" />
        <meta property="og:title" content="Benzinekosten berekenen | Gratis Brandstofkosten Calculator 2024" />
        <meta property="og:description" content="Bereken gratis uw brandstofkosten voor elke autorit. Actuele brandstofprijzen en nauwkeurige berekening van benzine- en dieselkosten." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.benzinekosten-berekenen.nl" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Benzinekosten berekenen | Gratis Calculator" />
        <meta name="twitter:description" content="Bereken gratis uw brandstofkosten voor elke autorit. Actuele brandstofprijzen en routeplanner." />
      </Helmet>

      <header className="text-center mb-12">
        <div className="flex items-center justify-center gap-2 mb-4">
          <Calculator className="w-8 h-8 text-blue-600" />
          <h1 className="text-3xl font-bold text-gray-800">Benzinekosten berekenen</h1>
        </div>
        <p className="text-gray-600 max-w-2xl mx-auto">
          <strong>Bereken</strong> snel en eenvoudig uw <strong>benzinekosten</strong> voor uw reis. Plan uw route en zie direct de kosten.
        </p>
      </header>

      <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8 mb-8">
        <div className="grid gap-8 md:grid-cols-2">
          <div className="space-y-6">
            <div className="space-y-4">
              <div>
                <label className="flex items-center gap-2 text-gray-700 mb-2">
                  <Route className="w-5 h-5" />
                  <span>Vertrekpunt</span>
                </label>
                <input
                  type="text"
                  value={fromAddress}
                  onChange={(e) => setFromAddress(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Bijv. Amsterdam Centraal"
                />
              </div>

              <div>
                <label className="flex items-center gap-2 text-gray-700 mb-2">
                  <ArrowRight className="w-5 h-5" />
                  <span>Bestemming</span>
                </label>
                <input
                  type="text"
                  value={toAddress}
                  onChange={(e) => setToAddress(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Bijv. Rotterdam Centraal"
                />
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="returnTrip"
                  checked={includeReturn}
                  onChange={(e) => setIncludeReturn(e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="returnTrip" className="flex items-center gap-2 text-gray-700">
                  <RotateCw className="w-5 h-5" />
                  <span>Retour reis meenemen</span>
                </label>
              </div>

              <button
                onClick={handleSearch}
                disabled={isSearching || !fromAddress || !toAddress}
                className="w-full bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 text-white font-semibold py-3 px-6 rounded-lg transition duration-200"
              >
                {isSearching ? 'Route zoeken...' : 'Route Zoeken'}
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="flex items-center gap-2 text-gray-700 mb-2">
                  <Fuel className="w-5 h-5" />
                  <span>Verbruik (L/100km)</span>
                </label>
                <input
                  type="text"
                  value={consumption}
                  onChange={(e) => setConsumption(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Bijv. 6,5"
                />
              </div>

              <div>
                <label className="flex items-center gap-2 text-gray-700 mb-2">
                  <Euro className="w-5 h-5" />
                  <span>Brandstofprijs (€/L)</span>
                </label>
                <input
                  type="text"
                  value={fuelPrice}
                  onChange={(e) => setFuelPrice(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Bijv. 2,089"
                />
              </div>
            </div>

            {result !== null && (
              <div className="bg-gray-50 rounded-xl p-6">
                <h2 className="text-xl font-semibold mb-4 flex items-center gap-2">
                  <Info className="w-5 h-5 text-blue-600" />
                  Resultaat
                </h2>
                <div className="space-y-4">
                  <div className="bg-white rounded-lg p-4 shadow-sm">
                    <p className="text-gray-600">Totale brandstofkosten:</p>
                    <p className="text-3xl font-bold text-green-600">
                      €{result.toFixed(2).replace('.', ',')}
                    </p>
                  </div>
                  <div className="text-sm text-gray-600">
                    <p>• Afstand: {distance} km{includeReturn ? ' (inclusief retour)' : ''}</p>
                    <p>• Verbruik: {consumption}L/100km</p>
                    <p>• Brandstofprijs: €{fuelPrice}/L</p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="h-[600px] rounded-xl overflow-hidden shadow-lg">
            <MapContainer
              center={[52.3676, 4.9041]}
              zoom={7}
              className="h-full w-full"
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {fromCoords && toCoords && (
                <RoutingMachine
                  from={fromCoords}
                  to={toCoords}
                  onRouteFound={handleRouteFound}
                />
              )}
            </MapContainer>
          </div>
        </div>
      </div>

      <div className="prose max-w-none mb-8">
        <h2 className="text-xl font-semibold mb-4">Handig en snel, <strong>bereken</strong> hier je <strong>benzinekosten</strong></h2>
        <p className="text-gray-700 mb-6">
          <strong>Benzinekosten</strong> kunnen hoog oplopen. Een rit naar willekeurige bestemming kost geld, maar hoeveel? Wij willen je helpen door het aanbieden van deze makkelijke online tool. Een reis per auto naar je vakantiebestemming, bijvoorbeeld Duitsland, Frankrijk of Italie wat kost dat aan brandstof? En wat zijn de <strong>benzinekosten</strong> per gereden km? Autohandelaren in de buurt
        </p>

        <h2 className="text-xl font-semibold mb-4">Hoe werkt het?</h2>
        <p className="text-gray-700 mb-6">
          Vul in je postcode of plaatsnaam en je bestemming, druk daarna op de knop <strong>Bereken</strong>. In het venster genaamd <strong>Benzinekosten</strong> kun je zien wat je ongeveer kwijt bent aan benzine tanken. Wij gebruiken de benzineprijs van Nederlands grootste oliemaatschappij. Deze wordt automatisch ververst en is dus actueel maar je kunt ook zelf een prijs invullen. Ook handig is het venster genaamd Kaart daar kun je direct de te rijden route bekijken. En uiteraard geldt hoe meer kilometers je rijdt des te meer geld je kwijt bent aan het rijden.
        </p>

        <h2 className="text-xl font-semibold mb-4">Actuele brandstofprijzen</h2>
        <p className="text-gray-700">
          Voor een <strong>bereken</strong>ing heb je natuurlijk ook de juiste prijzen nodig. Daarom verzamelen wij dagelijk de dagprijzen die automatisch worden opgehaald. Bekijk hier de brandstofprijzen van nederland.
        </p>
      </div>
    </>
  );
}

export default App;