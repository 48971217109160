import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import App from './App.tsx';
import Partners from './pages/Partners.tsx';
import Disclaimer from './pages/Disclaimer.tsx';
import OverOns from './pages/OverOns.tsx';
import KentekenCheck from './pages/KentekenCheck.tsx';
import Privacy from './pages/Privacy.tsx';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <App />,
      },
      {
        path: 'partners',
        element: <Partners />,
      },
      {
        path: 'disclaimer',
        element: <Disclaimer />,
      },
      {
        path: 'over-ons',
        element: <OverOns />,
      },
      {
        path: 'kenteken-check',
        element: <KentekenCheck />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
      },
    ],
  },
]);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </StrictMode>
);