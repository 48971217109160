import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Car } from 'lucide-react';

export default function KentekenCheck() {
  const [kenteken, setKenteken] = useState('');

  const formatKenteken = (value: string): string => {
    // Remove spaces and convert to uppercase
    let tmp = value.replace(/\s/g, '');
    let totalKenteken = '';
    let i = 0;

    // Process each character
    while (i < tmp.length) {
      const isCurrentNumeric = !isNaN(parseInt(tmp[i]));
      const isNextNumeric = i + 1 < tmp.length && !isNaN(parseInt(tmp[i + 1]));
      
      // Add hyphen between different types of characters (letters/numbers)
      if (i + 1 < tmp.length && 
          isCurrentNumeric !== isNextNumeric && 
          tmp[i] !== '-' && 
          tmp[i + 1] !== '-' &&
          (totalKenteken.match(/-/g) || []).length < 2) {
        totalKenteken += tmp[i] + '-';
      } else {
        totalKenteken += tmp[i];
      }
      i++;
    }

    // Handle 4-character segments
    if ((totalKenteken.match(/-/g) || []).length < 2) {
      const segments = totalKenteken.split('-');
      segments.forEach((segment, index) => {
        if (segment.length === 4) {
          totalKenteken = totalKenteken.replace(
            segment,
            `${segment.slice(0, 2)}-${segment.slice(2)}`
          );
        }
      });
    }

    // Clean up formatting
    if (totalKenteken.startsWith('-')) {
      totalKenteken = totalKenteken.slice(1);
    }

    // Handle multiple segments
    const segments = totalKenteken.split('-');
    if (segments.length >= 4) {
      totalKenteken = `${segments[0]}-${segments[1]}-${segments.slice(2).join('')}`;
    }

    return totalKenteken.toUpperCase();
  };

  const handleKentekenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const formattedValue = formatKenteken(value);
    setKenteken(formattedValue);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (kenteken.replace(/-/g, '').length >= 6) {
      window.open(`https://www.kentekencheck.nl/aff.php?x=55063b25e1c1732772af83ae75b2937b&kenteken=${kenteken.replace(/-/g, '')}`, '_blank');
    }
  };

  return (
    <>
      <Helmet>
        <title>Kenteken check | Gratis RDW Voertuiginformatie Opvragen</title>
        <meta name="description" content="Gratis kenteken check voor alle Nederlandse voertuigen. Direct toegang tot RDW-gegevens, technische specificaties, brandstofverbruik en onderhoudshistorie." />
        <meta name="keywords" content="kenteken check, rdw check, voertuiginformatie, auto gegevens, apk check, autogegevens opvragen" />
        <link rel="canonical" href="https://www.benzinekosten-berekenen.nl/kenteken-check" />
        <meta property="og:title" content="Kenteken check | Gratis RDW Voertuiginformatie" />
        <meta property="og:description" content="Gratis kenteken check voor alle Nederlandse voertuigen. Direct toegang tot RDW-gegevens en technische specificaties." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.benzinekosten-berekenen.nl/kenteken-check" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Kenteken check | RDW Voertuiginformatie" />
        <meta name="twitter:description" content="Gratis kenteken check voor alle Nederlandse voertuigen. Direct RDW-gegevens opvragen." />
      </Helmet>

      <header className="text-center mb-12">
        <div className="flex items-center justify-center gap-2 mb-4">
          <Car className="w-8 h-8 text-blue-600" />
          <h1 className="text-3xl font-bold text-gray-800">Kenteken check</h1>
        </div>
        <p className="text-gray-600">
          Vind alle informatie over uw voertuig met behulp van het kenteken.
        </p>
      </header>

      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label 
              htmlFor="kenteken" 
              id="kenteken-input-label"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Voer uw kenteken in
            </label>
            <div className="relative w-full max-w-[200px] mx-auto">
              {/* Dutch license plate styling */}
              <div className="absolute inset-0 bg-[#F7B731] rounded-lg border-2 border-black transform scale-[1.02]" />
              <input
                type="text"
                id="kenteken"
                className="kenteken-input relative w-full px-4 py-2 font-bold text-center text-black bg-[#FECF5B] border-2 border-black rounded-lg tracking-wider uppercase focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                value={kenteken}
                onChange={handleKentekenChange}
                placeholder="XX-XX-XX"
                maxLength={8}
                style={{ fontFamily: "'License Plate', sans-serif" }}
              />
              {/* NL indicator */}
              <div className="absolute -left-8 top-1/2 -translate-y-1/2 bg-[#003399] text-white px-1 py-0.5 text-xs font-bold rounded border border-white">
                NL
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full max-w-[200px] mx-auto block bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors"
          >
            kentekencheck
          </button>
        </form>

        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Wat krijgt u te zien?</h2>
          <ul className="space-y-2 text-gray-700">
            <li>• Merk en model</li>
            <li>• Bouwjaar</li>
            <li>• Brandstoftype</li>
            <li>• Gemiddeld verbruik</li>
            <li>• CO2-uitstoot</li>
            <li>• APK vervaldatum</li>
            <li>• Technische specificaties</li>
          </ul>
        </div>

        <div className="mt-8 p-4 bg-gray-50 rounded-lg">
          <p className="text-sm text-gray-600">
            De kentekencheck maakt gebruik van de officiële database voor de meest actuele voertuiginformatie.
          </p>
        </div>
      </div>
    </>
  );
}