import React from 'react';
import { Car } from 'lucide-react';
import { Link, Outlet } from 'react-router-dom';

export default function Layout() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-6xl mx-auto px-4 py-8">
        {/* Main content */}
        <Outlet />

        {/* Footer */}
        <footer className="text-center text-gray-600 text-sm">
          <div className="flex items-center justify-center gap-2 mb-4">
            <Car className="w-5 h-5" />
            <p>Brandstofkosten calculator</p>
          </div>
          <div className="flex flex-wrap justify-center gap-x-6 gap-y-2 mb-4">
            <Link to="/partners" className="text-blue-600 hover:text-blue-800 transition-colors">Partners</Link>
            <span className="text-gray-300">|</span>
            <Link to="/disclaimer" className="text-blue-600 hover:text-blue-800 transition-colors">Disclaimer</Link>
            <span className="text-gray-300">|</span>
            <Link to="/over-ons" className="text-blue-600 hover:text-blue-800 transition-colors">Over ons</Link>
            <span className="text-gray-300">|</span>
            <Link to="/kenteken-check" className="text-blue-600 hover:text-blue-800 transition-colors">Kentekencheck</Link>
            <span className="text-gray-300">|</span>
            <Link to="/privacy" className="text-blue-600 hover:text-blue-800 transition-colors">Privacy</Link>
            <span className="text-gray-300">|</span>
            <a href="https://www.calcolobenzina.it/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 transition-colors">Calcolobenzina.it</a>
          </div>
          <p>©2024 <a href="https://www.benzinekosten-berekenen.nl" className="text-blue-600 hover:text-blue-800 transition-colors">Benzinekosten berekenen</a></p>
        </footer>
      </div>
    </div>
  );
}