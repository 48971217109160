import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Shield } from 'lucide-react';

export default function Disclaimer() {
  return (
    <>
      <Helmet>
        <title>Disclaimer | Benzinekosten berekenen</title>
        <meta name="description" content="Disclaimer en juridische informatie voor het gebruik van Benzinekosten berekenen." />
        <link rel="canonical" href="https://www.benzinekosten-berekenen.nl/disclaimer" />
        <meta property="og:title" content="Disclaimer | Benzinekosten berekenen" />
        <meta property="og:description" content="Disclaimer en juridische informatie voor het gebruik van Benzinekosten berekenen." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.benzinekosten-berekenen.nl/disclaimer" />
      </Helmet>

      <header className="text-center mb-12">
        <div className="flex items-center justify-center gap-2 mb-4">
          <Shield className="w-8 h-8 text-blue-600" />
          <h1 className="text-3xl font-bold text-gray-800">
            <a href="https://www.benzinekosten-berekenen.nl/" className="hover:text-blue-600 transition-colors">
              Benzinekosten berekenen
            </a>
          </h1>
        </div>
      </header>

      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
        <div className="space-y-6">
          <p className="text-gray-700 leading-relaxed">
            Techniek is mooi en handig maar heel soms ook faalbaar. Het gebruik van onze online tool is gratis maar er kunnen geen rechten aan worden ontleend. De getoonde gegevens (zoals de prijs en afstand) zijn onder voorbehoud en kunnen dus afwijken. Gebruik onze website dan ook om een indicatie te verkrijgen van de benzinekosten.
          </p>
          
          <p className="text-gray-700 leading-relaxed">
            Wilt u hoe lang of ver een afstand is kijkt u dan s.v.p. op onze andere website{' '}
            <a 
              href="https://www.afstand-berekenen.nl" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 transition-colors"
            >
              www.Afstand-berekenen.nl
            </a>.
          </p>
        </div>
      </div>
    </>
  );
}