import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Handshake } from 'lucide-react';

export default function Partners() {
  return (
    <>
      <Helmet>
        <title>Partners | Benzinekosten berekenen</title>
        <meta name="description" content="Onze partners die bijdragen aan het succes van Benzinekosten berekenen." />
        <link rel="canonical" href="https://www.benzinekosten-berekenen.nl/partners" />
        <meta property="og:title" content="Partners | Benzinekosten berekenen" />
        <meta property="og:description" content="Onze partners die bijdragen aan het succes van Benzinekosten berekenen." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.benzinekosten-berekenen.nl/partners" />
      </Helmet>

      <header className="text-center mb-12">
        <div className="flex items-center justify-center gap-2 mb-4">
          <Handshake className="w-8 h-8 text-blue-600" />
          <h1 className="text-3xl font-bold text-gray-800">
            <a href="https://www.benzinekosten-berekenen.nl/" className="hover:text-blue-600 transition-colors">
              Benzinekosten berekenen
            </a>
          </h1>
        </div>
      </header>

      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
        <div className="space-y-6">
          <p className="text-gray-700">
            Wij verzamelen graag links naar websites die een soortgelijk onderwerp beschrijven. Zoals brandstof of auto, motoren enzovoorts. Hieronder staan onze partners, een bezoekje waard om eens te kijken.
          </p>

          <ul className="space-y-4">
            <li>
              <a 
                href="https://www.btwberekenen123.nl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                BTWberekenen123.nl
              </a>
            </li>
            <li>
              <a 
                href="https://www.afstand-berekenen.nl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                Afstand-berekenen.nl
              </a>
            </li>
            <li>
              <a 
                href="#" 
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                Motorkleding, helmen en accessoires
              </a>
            </li>
            <li>
              <a 
                href="#" 
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                Sloopauto
              </a>
            </li>
            <li>
              <a 
                href="#" 
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                Autogarage Bourguignon
              </a>
            </li>
            <li>
              <a 
                href="https://www.debijtellingberekenen.nl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                Debijtellingberekenen.nl
              </a>
            </li>
            <li>
              <a 
                href="https://www.ex-leaseauto-kopen.nl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                Ex-leaseauto-kopen.nl
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}