import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Lock } from 'lucide-react';

export default function Privacy() {
  return (
    <>
      <Helmet>
        <title>Privacy | Benzinekosten berekenen</title>
        <meta name="description" content="Privacyverklaring van Benzinekosten berekenen - Lees hoe wij omgaan met uw persoonsgegevens." />
        <link rel="canonical" href="https://www.benzinekosten-berekenen.nl/privacy" />
        <meta property="og:title" content="Privacy | Benzinekosten berekenen" />
        <meta property="og:description" content="Privacyverklaring van Benzinekosten berekenen - Lees hoe wij omgaan met uw persoonsgegevens." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.benzinekosten-berekenen.nl/privacy" />
      </Helmet>

      <header className="text-center mb-12">
        <div className="flex items-center justify-center gap-2 mb-4">
          <Lock className="w-8 h-8 text-blue-600" />
          <h1 className="text-3xl font-bold text-gray-800">
            <a href="https://www.benzinekosten-berekenen.nl/" className="hover:text-blue-600 transition-colors">
              Benzinekosten berekenen
            </a>
          </h1>
        </div>
      </header>

      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
        <div className="space-y-8">
          <section>
            <p className="text-gray-700">
              Pruisisch Blauw, gevestigd aan De Huisakker 30, 7041tg, s-Heerenberg, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Contactgegevens</h2>
            <ul className="space-y-2 text-gray-700">
              <li>Website: <a href="https://benzinekosten-berekenen.nl" className="text-blue-600 hover:text-blue-800">benzinekosten-berekenen.nl</a></li>
              <li>Website: <a href="https://pruisischblauw.nl" className="text-blue-600 hover:text-blue-800">pruisischblauw.nl</a></li>
              <li>Adres: De Huisakker 30, 7041tg, s-Heerenberg</li>
              <li>Telefoon: <a href="tel:+31681667115" className="text-blue-600 hover:text-blue-800">+31681667115</a></li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Persoonsgegevens die wij verwerken</h2>
            <p className="text-gray-700 mb-4">
              Pruisisch Blauw verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>IP-adres</li>
              <li>Gegevens over jouw surfgedrag over verschillende websites heen (bijvoorbeeld omdat dit bedrijf onderdeel is van een advertentienetwerk)</li>
              <li>Internetbrowser en apparaat type</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
            <p className="text-gray-700">
              Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via <a href="mailto:info@pruisischblauw.nl" className="text-blue-600 hover:text-blue-800">info@pruisischblauw.nl</a>, dan verwijderen wij deze informatie.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
            <p className="text-gray-700">
              Pruisisch Blauw verwerkt jouw persoonsgegevens voor de volgende doelen:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2 mt-4">
              <li>Pruisisch Blauw analyseert jouw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op jouw voorkeuren.</li>
              <li>Pruisisch Blauw volgt jouw surfgedrag over verschillende websites waarmee wij onze producten en diensten afstemmen op jouw behoefte.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Hoe lang we persoonsgegevens bewaren</h2>
            <p className="text-gray-700">
              Pruisisch Blauw bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de persoonsgegevens:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2 mt-4">
              <li>Conform hosting leverancier (webhosting log)</li>
              <li>Conform Google en Affiliate leverancier (log)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Delen van persoonsgegevens met derden</h2>
            <p className="text-gray-700">
              Pruisisch Blauw deelt jouw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die je gegevens verwerken in onze opdracht, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Pruisisch Blauw blijft verantwoordelijk voor deze verwerkingen. Daarnaast verstrekt Pruisisch Blauw jouw persoonsgegevens aan andere derden. Dit doen wij alleen met jouw nadrukkelijke toestemming.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
            <p className="text-gray-700 mb-4">
              Pruisisch Blauw gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van je computer, tablet of smartphone. Pruisisch Blauw gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die jouw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden.
            </p>
            <p className="text-gray-700 mb-4">
              Bij jouw eerste bezoek aan onze website hebben wij je al geinformeerd over deze cookies en hebben we je toestemming gevraagd voor het plaatsen ervan. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen. Zie voor een toelichting: <a href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">veiliginternetten.nl</a>
            </p>
            <p className="text-gray-700 mb-4">
              Op deze website worden ook cookies geplaatst door derden. Dit zijn bijvoorbeeld adverteerders en/of de sociale media-bedrijven. Hieronder een overzicht:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Googly Analytics, Advertising</li>
              <li>Microsoft BING</li>
              <li>Facebook</li>
              <li>Instagram</li>
              <li>Daisycon, AWIN, Tradedoubler, Tradetracker (affiliate netwerken)</li>
            </ul>
            <p className="text-gray-700 mt-4">
              Note: cookie voorkeur is te wijzigen via de knop linksonder.<br />
              Note: Hoe Google cookies gebruikt
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Gegevens inzien, aanpassen of verwijderen</h2>
            <p className="text-gray-700 mb-4">
              Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Pruisisch Blauw en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.
            </p>
            <p className="text-gray-700 mb-4">
              Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar <a href="mailto:info@pruisischblauw.nl" className="text-blue-600 hover:text-blue-800">info@pruisischblauw.nl</a>. Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.
            </p>
            <p className="text-gray-700">
              Pruisisch Blauw wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">autoriteitpersoonsgegevens.nl</a>
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Hoe wij persoonsgegevens beveiligen</h2>
            <p className="text-gray-700">
              Pruisisch Blauw neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via <a href="mailto:info@pruisischblauw.nl" className="text-blue-600 hover:text-blue-800">info@pruisischblauw.nl</a>
            </p>
          </section>
        </div>
      </div>
    </>
  );
}